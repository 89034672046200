export function MenuAim(rowSelector) {

    let menu         = null,
        activeRow    = null,
        lastDelayLoc = null,
        timeoutId    = null,
        activeClass  = 'megamenu--open',
        tolerance    = 90;  // bigger = more forgivey when entering submenu

    const DELAY = 300; // ms delay when user appears to be entering submenu

    return {
        init         : _init,
        setActiveRow : _setActiveRow,
        activateRow  : _activateRow
    };

    function _init(_menu) {
        menu = _menu;
        if(menu) {

            menu.addEventListener('mouseleave', _mouseLeaveMenu);

            let rows = menu.querySelectorAll(rowSelector);

            rows.forEach((row) => {
                row.addEventListener('mouseenter', _mouseEnterRow);
            });
        }
    }

    function _setActiveRow(row) {
        activeRow = row;
    }

    function _activateRow(row) {
        activeRow = row;
        _activateSubmenu(row);
    }

    /**
     * Function to call when a row is purposefully activated.
     */
    function _activateSubmenu(row) {
        row.classList.add(activeClass);
    }

    /**
     * Function to call when a row is deactivated.
     */
    function _deactivateSubmenu(row) {
        row.classList.remove(activeClass);
    }

    /**
     * Cancel possible row activations when leaving the menu entirely
     */
    function _mouseLeaveMenu() {

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }

    /**
     * Trigger a possible row activation whenever entering a new row.
     */
    function _mouseEnterRow(e) {

        let that = e.target;

        if(window.innerWidth >= 992) {

            if (timeoutId) {
                // Cancel any previous activation delays
                clearTimeout(timeoutId);
            }

            _possiblyActivate(that);
        }
    }

    /**
     * Activate a menu row.
     */
     function _activate(row) {

        if (row === activeRow) {
            return;
        }

        if (activeRow) {
            _deactivateSubmenu(activeRow);
        }

        _activateSubmenu(row);
        activeRow = row;
    }

    /**
     * Possibly activate a menu row. If mouse movement indicates that we
     * shouldn't activate yet because user may be trying to enter
     * a submenu's content, then delay and check again later.
     */
    function _possiblyActivate(row) {

        let delay = _activationDelay();

        if (delay) {
            timeoutId = setTimeout(function() {
                _possiblyActivate(row);
            }, delay);
        } else {
            _activate(row);
        }
    }

    /**
     * Return the amount of time that should be used as a delay before the
     * currently hovered row is activated.
     *
     * Returns 0 if the activation should happen immediately. Otherwise,
     * returns the number of milliseconds that should be delayed before
     * checking again to see if the row should be activated.
     */
    function _activationDelay() {

        if (!activeRow || (activeRow && activeRow.className.indexOf(activeClass) === -1)) {
            // If there is no other submenu row already active, then
            // go ahead and activate immediately.
            return 0;
        }
        let rect = menu.getBoundingClientRect(),
            offset = {
                top: rect.top,
                left: rect.left
            },
            upperLeft = {
                x: offset.left,
                y: offset.top - tolerance
            },
            upperRight = {
                x: offset.left + menu.offsetHeight,
                y: upperLeft.y
            },
            lowerLeft = {
                x: offset.left,
                y: offset.top + menu.offsetHeight + tolerance
            },
            lowerRight = {
                x: offset.left + menu.offsetWidth,
                y: lowerLeft.y
            },
            loc = window.mouseLocs[window.mouseLocs.length - 1],
            prevLoc = window.mouseLocs[0];

        if (!loc) {
            return 0;
        }

        if (!prevLoc) {
            prevLoc = loc;
        }

        if (prevLoc.x < offset.left || prevLoc.x > lowerRight.x ||
            prevLoc.y < offset.top || prevLoc.y > lowerRight.y) {
            // If the previous mouse location was outside of the entire
            // menu's bounds, immediately activate.
            return 0;
        }

        if (lastDelayLoc &&
            loc.x === lastDelayLoc.x && loc.y === lastDelayLoc.y) {
            // If the mouse hasn't moved since the last time we checked
            // for activation status, immediately activate.
            return 0;
        }

        // Detect if the user is moving towards the currently activated
        // submenu.
        //
        // If the mouse is heading relatively clearly towards
        // the submenu's content, we should wait and give the user more
        // time before activating a new row. If the mouse is heading
        // elsewhere, we can immediately activate a new row.
        //
        // We detect this by calculating the slope formed between the
        // current mouse location and the upper/lower right points of
        // the menu. We do the same for the previous mouse location.
        // If the current mouse location's slopes are
        // increasing/decreasing appropriately compared to the
        // previous's, we know the user is moving toward the submenu.
        //
        // Note that since the y-axis increases as the cursor moves
        // down the screen, we are looking for the slope between the
        // cursor and the upper right corner to decrease over time, not
        // increase (somewhat counterintuitively).
        function slope(a, b) {
            return (b.y - a.y) / (b.x - a.x);
        }

        let decreasingCorner = upperRight,
            increasingCorner = lowerRight;

        let decreasingSlope = slope(loc, decreasingCorner),
            increasingSlope = slope(loc, increasingCorner),
            prevDecreasingSlope = slope(prevLoc, decreasingCorner),
            prevIncreasingSlope = slope(prevLoc, increasingCorner);

        if (decreasingSlope < prevDecreasingSlope &&
            increasingSlope > prevIncreasingSlope) {
            // Mouse is moving from previous location towards the
            // currently activated submenu. Delay before activating a
            // new menu row, because user may be moving into submenu.
            lastDelayLoc = loc;
            return DELAY;
        }

        lastDelayLoc = null;
        return 0;
    }

};
