import 'popper.js/dist/popper.js';
import 'bootstrap/js/dist/util.js';
import 'bootstrap/js/dist/tab.js';
import 'bootstrap/js/dist/modal.js';
import 'bootstrap/js/dist/collapse.js';
import 'bootstrap/js/src/dropdown.js';
import {debounce, throttle} from 'lodash';
import {MenuAim} from './menuAim';


(function($) {
    window.mouseLocs = [];
    //init mousemove for menuAim
    document.addEventListener('mousemove', function(e) {
        window.mouseLocs.push({
            x: e.pageX - $(document).scrollLeft(),
            y: e.pageY - $(document).scrollTop()
        });
        if (window.mouseLocs.length > 3) {
            window.mouseLocs.shift();
        }
    });

    //init desktop level 2 menus
    let menus = document.querySelectorAll('.megamenu__dropdown--0');
    menus.forEach((menu) => {
        new MenuAim('.megamenu__item--1').init(menu);
    });
    menus = document.querySelectorAll('.megamenu__dropdown--1');
    menus.forEach((menu) => {
        new MenuAim('.megamenu__item--2').init(menu);
    });

    $('#menu-aim .nav-item').on('mouseenter', function(){
        if(typeof closeDebounce !== 'undefined'){
            closeDebounce.cancel();
        }
        $('#menu-aim .nav-item').removeClass('megamenu--open');
        $(this).addClass('megamenu--open');
    })

    let closeDebounce = _.debounce(closeMenu, 250);

    $('#menu-aim').on('mouseleave', closeDebounce);
    function closeMenu(){
        $('.megamenu--open').removeClass('megamenu--open');
    }

    function changeMenuLvl0(elm){
        menuItems[0] = $(elm).attr('id');

    }

    if($('.single-produkte').length){

        $(document).ready(function(e) {
            if(!isOnScreen('.produkte_hero')){
                jQuery('.produkte__header').addClass("fixed-top");
            }else {
                jQuery('.produkte__header').removeClass("fixed-top");
            }
        });

        $(window).on('scroll', function(e) {
           if(!isOnScreen('.produkte_hero')){
               jQuery('.produkte__header').addClass("fixed-top");
           }else {
               jQuery('.produkte__header').removeClass("fixed-top");
           }
        });


    }

    function isOnScreen(elem) {
        // if the element doesn't exist, abort
        if( elem.length == 0 ) {
            return;
        }
        var $window = jQuery(window)
        var viewport_top = $window.scrollTop()
        var viewport_height = $window.height()
        var viewport_bottom = viewport_top + viewport_height
        var $elem = jQuery(elem)
        var top = $elem.offset().top
        var height = $elem.height()
        var bottom = top + height

        return (top >= viewport_top && top < viewport_bottom) ||
            (bottom > viewport_top && bottom <= viewport_bottom) ||
            (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    }

    if( $('#wppb-loginform').length > 0 ){
        $('.login-remember').click(function(){
            if ( $('input#rememberme').is(':checked') ) {
                $('.login-remember label').addClass('active');
            }else{
                $('.login-remember label').removeClass('active');
            }
        });
    }

}(jQuery));



